body {
    font-family: 'Roboto', sans-serif;
}

.section-appear,
.question-appear,
.new-section-question-appear {
  opacity: 0.01;
  padding-top: 10px;
}
  
.section-appear.section-appear-active,
.question-appear.question-appear-active,
.new-section-question-appear.new-section-question-appear-active {
  opacity: 1;
  padding-top: 0px;
  transition: all .4s ease-in;
}

.new-section-question-appear {
  transition-delay: .4s;
}
  
.new-section-question-appear.new-section-question-appear-active {
  transition-delay: .4s;
}

.page-header-header-appear {
  opacity: 0.01;
  padding-top: 10px;
}
  
.page-header-header-appear.page-header-header-appear-active {
  opacity: 1;
  padding-top: 0px;
  transition: all .4s ease-in;
}

.page-header-body-appear {
  opacity: 0.01;
  padding-top: 10px;
  transition-delay: .4s;
}
  
.page-header-body-appear.page-header-body-appear-active {
  opacity: 1;
  padding-top: 0px;
  transition: all .4s ease-in;
  transition-delay: .4s;

}